$(document).foundation();
function init() {
  window.addEventListener('scroll', function(e) {
    var distanceY = window.pageYOffset || document.documentElement.scrollTop,
      shrinkOn = 120,
      windowWidth = $(window).width(),
      header = document.querySelector('#header-main');
    if (distanceY > shrinkOn && windowWidth > 1024) {
      $('#header-main').addClass('smaller');
      $('.sticky-nav li.news-cta').removeClass('always-open');
    } else {
      $('.sticky-nav li.news-cta').addClass('always-open');

      if ($('#header-main').hasClass('smaller')) {
        $('#header-main').removeClass('smaller');
      }
    }
  });
}
window.onload = init();

var clicked = 0;
$('.menu-icon').click(function(e) {
  if (clicked == 0) {
    $('header#header-main').addClass('open');
    $('body').addClass('no-scroll');
    clicked = 1;
  } else {
    $('header#header-main').removeClass('open');
    $('body').removeClass('no-scroll');
    clicked = 0;
  }
});

$('.sticky-nav li.news-cta').hover(function() {
  $(this).toggleClass('active');
});

if ($('.newsletter').length) {
  $('html, body').animate(
    {
      scrollTop: $('.newsletter').offset().top,
    },
    500
  );
}

if (document.querySelectorAll('.blf-map').length > 0) {
// styles
  var normal = new ol.style.Style({
    image: new ol.style.Icon({
      anchor: [0.5, 46],
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixels',
      src: 'http://localhost:8000/assets/img/svg/Pinn.png',
    }),
  });

  var neu = new ol.style.Style({
    image: new ol.style.Icon({
      anchor: [0.5, 46],
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixels',
      src: 'http://localhost:8000/assets/img/svg/NEU.png',
    }),
  });

// Lieferanten
  var goer = new ol.Feature({
    geometry: new ol.geom.Point([400, 675]),
    name: 'Goer',
    population: 4000,
    rainfall: 300,
  });
  goer.setStyle(normal);

  var diepensiepen = new ol.Feature({
    geometry: new ol.geom.Point([280, 600]),
    name: 'Diepensiepen',
    population: 4000,
    rainfall: 500,
  });
  diepensiepen.setStyle(normal);

  var zutelgte = new ol.Feature({
    geometry: new ol.geom.Point([475, 860]),
    name: 'Zutelgte',
    population: 4000,
    rainfall: 500,
  });
  zutelgte.setStyle(normal);

  var columbus = new ol.Feature({
    geometry: new ol.geom.Point([300, 850]),
    name: 'Columbus',
    population: 4000,
    rainfall: 500,
  });
  columbus.setStyle(normal);

  var sicking = new ol.Feature({
    geometry: new ol.geom.Point([275, 835]),
    name: 'Sicking',
    population: 4000,
    rainfall: 500,
  });
  sicking.setStyle(normal);

  var wiesehoff = new ol.Feature({
    geometry: new ol.geom.Point([315, 840]),
    name: 'Wiesehoff',
    population: 4000,
    rainfall: 500,
  });
  wiesehoff.setStyle(normal);

  var timmFleisch = new ol.Feature({
    geometry: new ol.geom.Point([280, 750]),
    name: 'TimmFleisch',
    population: 4000,
    rainfall: 500,
  });
  timmFleisch.setStyle(normal);

  var wulfert = new ol.Feature({
    geometry: new ol.geom.Point([225, 675]),
    name: 'Wulfert',
    population: 4000,
    rainfall: 500,
  });
  wulfert.setStyle(normal);

  var tersteegen = new ol.Feature({
    geometry: new ol.geom.Point([218, 670]),
    name: 'Tersteegen',
    population: 4000,
    rainfall: 500,
  });
  tersteegen.setStyle(normal);

  var steinhaus = new ol.Feature({
    geometry: new ol.geom.Point([500, 450]),
    name: 'Steinhaus',
    population: 4000,
    rainfall: 500,
  });
  steinhaus.setStyle(normal);

  var haribo = new ol.Feature({
    geometry: new ol.geom.Point([475, 200]),
    name: 'Haribo',
    population: 4000,
    rainfall: 500,
  });
  haribo.setStyle(normal);

  var kuchenmeister = new ol.Feature({
    geometry: new ol.geom.Point([600, 660]),
    name: 'Kuchenmeister',
    population: 4000,
    rainfall: 500,
  });
  kuchenmeister.setStyle(normal);

  var huelshorst = new ol.Feature({
    geometry: new ol.geom.Point([580, 750]),
    name: 'Huelshorst',
    population: 4000,
    rainfall: 500,
  });
  huelshorst.setStyle(normal);

  var vectorSource = new ol.source.Vector({
    features: [
      goer,
      diepensiepen,
      zutelgte,
      columbus,
      sicking,
      wiesehoff,
      timmFleisch,
      wulfert,
      tersteegen,
      steinhaus,
      haribo,
      kuchenmeister,
      huelshorst,
    ],
  });

  var vectorLayer = new ol.layer.Vector({
    source: vectorSource,
  });

  var extent = [0, 0, 1024, 968];
  var projection = new ol.proj.Projection({
    code: 'xkcd-image',
    units: 'pixels',
    extent: extent,
  });

  var map = new ol.Map({
    layers: [
      new ol.layer.Image({
        source: new ol.source.ImageStatic({
          url: 'http://localhost:8000/assets/img/svg/map.png',
          projection: projection,
          imageExtent: extent,
        }),
      }),
      vectorLayer,
    ],
    target: document.getElementById('map'),
    view: new ol.View({
      projection: projection,
      center: ol.extent.getCenter(extent),
      zoom: 1,
      maxZoom: 5,
    }),
  });

  var zoomslider = new ol.control.ZoomSlider();
  map.addControl(zoomslider);

  var elements = [...document.querySelectorAll('.popup')];
  let popups = [];
  console.log(elements);
  elements.forEach(function(element) {
    var popup = new ol.Overlay({
      element: element,
      positioning: 'bottom-center',
      stopEvent: false,
      offset: [0, -50],
    });
    map.addOverlay(popup);

    popups.push(popup);
  });

  map.on('click', function(evt) {
    var feature = map.forEachFeatureAtPixel(evt.pixel, function(feature) {
      return feature;
    });
    if (feature) {
      var coordinates = feature.getGeometry().getCoordinates();

      switch (feature.get('name')) {
        case 'Goer':
          togglePopup(coordinates, 0);
          break;
        case 'Diepensiepen':
          togglePopup(coordinates, 1);
          break;
        case 'Zutelgte':
          togglePopup(coordinates, 2);
          break;
        case 'Columbus':
          togglePopup(coordinates, 3);
          break;
        case 'Sicking':
          togglePopup(coordinates, 4);
          break;
        case 'Wiesehoff':
          togglePopup(coordinates, 5);
          break;
        case 'TimmFleisch':
          togglePopup(coordinates, 6);
          break;
        case 'Wulfert':
          togglePopup(coordinates, 7);
          break;
        case 'Tersteegen':
          togglePopup(coordinates, 8);
          break;
        case 'Steinhaus':
          togglePopup(coordinates, 9);
          break;
        case 'Haribo':
          togglePopup(coordinates, 10);
          break;
        case 'Kuchenmeister':
          togglePopup(coordinates, 11);
          break;
        case 'Huelshorst':
          togglePopup(coordinates, 12);
          break;
      }
    }
  });

  function togglePopup(coordinates, index) {
    elements[index].classList.toggle('popup-active');
    popups[index].setPosition(coordinates);
  }

  map.on('pointermove', function(e) {
    if (e.dragging) {
      return;
    }
    var pixel = map.getEventPixel(e.originalEvent);
    var hit = map.hasFeatureAtPixel(pixel);
    map.getTarget().style.cursor = hit ? 'pointer' : '';
  });

  $('.switch-map').click(function() {
    $(this).toggleClass('active');
    $('.switch-list').removeClass('active');
    $('.blf-map').removeClass('none');
    $('.blf-table').addClass('none');
  });

  $('.switch-list').click(function() {
    $(this).toggleClass('active');
    $('.switch-map').removeClass('active');
    $('.blf-table').removeClass('none');
    $('.blf-map').addClass('none');
  });
}

var windowHeight = window.innerHeight;
var windowScroll = window.pageYOffset;

$(window).resize(function() {
  windowHeight = $(window).height();

  if ($(window).width() >= 1024) {
    $('header#header-main').removeClass('open');
    $('body').removeClass('no-scroll');
    clicked = 0;
  }

  if (document.querySelectorAll('.blf-map').length > 0) {
    if ($(window).width() <= 468) {
      map.getView().animate({
        zoom: 0.5,
        duration: 250,
      });
    }

    if ($(window).width() >= 468) {
      map.getView().animate({
        zoom: 1,
        duration: 250,
      });
    }
  }
});

$(window).trigger('resize');

$(window).scroll(function() {
  windowScroll = window.pageYOffset;

  if (windowScroll >= windowHeight) {
    $('.sticky-nav-label').removeClass('active');
    $('.sticky-nav-icon').addClass('active');
  } else {
    $('.sticky-nav-label').addClass('active');
    $('.sticky-nav-icon').removeClass('active');
  }
});

var navItems = document.querySelectorAll('.has-submenu');

navItems.forEach((navItem) => {
  navItem.addEventListener('click', function() {
    navItem.classList.toggle('--active');
  });
});

if (document.querySelectorAll('.metaSearch form').length > 0) {
  let searchBars = document.querySelectorAll('.metaSearch form');
  let metaSearchBar = document.querySelectorAll('.metaSearch');

  metaSearchBar.forEach((searchBar) => {
    window.addEventListener('click', function (e) {
      if (searchBar.contains(e.target)) {
        searchBar.classList.add('--active');
      } else if (!searchBar.querySelector('input').value) {
        searchBar.classList.remove('--active');
        if (searchBar.nextElementSibling) {
          searchBar.classList.remove('--active');
        }
      } else {
        searchBar.classList.remove('--active');
      }

      searchBar.querySelector('input').addEventListener('input', function (e) {
        if (
            searchBar.contains(e.target) &&
            searchBar.querySelector('input').value.length > 0
        ) {
          searchBar.classList.add('--active');
        } else {
          searchBar.classList.remove('--active');
        }
      });
    });
  });
}
